import { lazy } from 'react';
import { Route } from 'react-router-dom';
import Status404 from '../../StatusError/Status404';

const BannerGVK = lazy(() => import('../BannerGVKInsurance').then(({ BannerGVKInsurance }) => ({ default: BannerGVKInsurance })));
const GVKSpring2022 = lazy(() => import('../GVKSpring2022').then(({ GVKSpring2022 }) => ({ default: GVKSpring2022 })));
const CSI2022 = lazy(() => import('../CSI2022').then(({ CSI2022 }) => ({ default: CSI2022 })));
const ServicesBlockTFinish = lazy(() =>
  import('../ServicesBlockTFinish').then(({ ServicesBlockTFinish }) => ({ default: ServicesBlockTFinish }))
);
const PROMnenie = lazy(() => import('../PRO_MNENIE').then(({ PROMnenie }) => ({ default: PROMnenie })));
const CampaignProMnenieEnd = lazy(() =>
  import('../PRO_MNENIE/CampaignProMnenieEnd').then(({ CampaignProMnenieEnd }) => ({ default: CampaignProMnenieEnd }))
);
const SurveyFinal = lazy(() => import('../SurveyFinal').then(({ SurveyFinal }) => ({ default: SurveyFinal })));
const Dashboard = lazy(() => import('../PRO_MNENIE/Dashboard').then(({ Dashboard }) => ({ default: Dashboard })));
const Dashboard2 = lazy(() => import('../PRO_MNENIE/Dashboard2').then(({ Dashboard2 }) => ({ default: Dashboard2 })));

const LandingPagesRoutes = (
  <Route path="landings">
    <Route path="gvk_insurance" element={<BannerGVK />} />
    <Route path="gvk_spring_2022" element={<GVKSpring2022 />} />
    <Route path="csi_infra_platforms" element={<CSI2022 />} />
    <Route path="mnenie" element={<PROMnenie />} />
    <Route path="pro_mnenie" element={<PROMnenie />} />
    <Route path="pro_mnenie/dashboard" element={<Dashboard />} />
    <Route path="pro_mnenie/dashboard2" element={<Dashboard2 />} />
    <Route path="services_block_t" element={<ServicesBlockTFinish />} />
    <Route path="mnenie_thanks" element={<SurveyFinal />} />
    <Route path="*" element={<Status404 />} />
  </Route>
);

export { LandingPagesRoutes };
