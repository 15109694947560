import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceInMilliseconds, formatISO } from "date-fns";

import { index } from '../../api/surveysApi';
import {
  formatMs,
  getMsFromInputValue
} from "../../components/Surveys/Survey_v2/SurveyView/Question/QuestionTypes/QTimer/helpers/date";
import { IAnswer } from '../../components/Surveys/Survey_v2/types';
import { IDemoInformation } from '../../models/surveys/Campaigns/IDemoInformation';
import { IRule } from '../../models/surveys/Survey/Rule/IRule';
import { IQuestionWithError, IRuleValue, ISurveysState } from '../constants';
import { commonApi } from "../queries/common.api";
import { ERegisterAnswersOperation } from "../queries/survey/types/registerAnswers/ERegisterAnswersOperation";
import { IRegisterAnswersDTO } from "../queries/survey/types/registerAnswers/IRegisterAnswersDTO";
import { RootState, store } from '../storeToolkit';

export const sendAnswersToBackend = createAsyncThunk(
  'surveys/sendAnswersToBackend', // отображается в dev tools
  async ({ surveyId, surveyPassingId, allAnswers, operation }: {
    surveyId: string,
    surveyPassingId: string,
    allAnswers: Array<IAnswer>,
    operation: ERegisterAnswersOperation
  }, { dispatch }) => {
    /**
     * при изменении ответов на вопрос составляем объект с ответами и отправляем на бэкенд
     */
    const answersForRegister: IRegisterAnswersDTO = {
      operation: operation,
      passingId: surveyPassingId || '',
      surveyInstanceId: surveyId,
      // соберём все оставленные ответы
      responses: allAnswers.map(answer => {
        return {
          options: ['multiplechoice','singlechoice','listchoice'].includes(answer.questionType)
            ? (answer.options || []).map(opt => ({
              optionId: opt.optionId || '',
              optionLabel: opt.optionLabel || ''
            }))
            : [],
          otherOption: Boolean(answer.ownVariant),
          otherOptionText: answer.ownVariant || '',
          questionId: answer?.questionId || '',
          questionLabel: answer?.questionLabel || '',
          questionType: answer?.questionType || '',
          questionCategory: '',
          questionCode: '',
          text: answer?.questionType === 'comment'
            ? answer?.value as string || ''
            : answer?.questionType === 'timer'
              ? answer?.value as string || ''
              : '',
          value: answer?.questionType === 'scale' || answer?.questionType === 'rating'
            ? parseInt(answer?.value.toString())
            : 0,
          timer: answer?.timer
        }
      })
    }

    await dispatch((commonApi.endpoints as any).registerAnswers.initiate(answersForRegister))
  })

// Thunk для создания сессии
export const sessionOpen = createAsyncThunk(
  'surveys/sessionOpen', // отображается в dev tools
  async (campaign: string, { rejectWithValue }) => {
    try {
      return await index.sessionOpen(campaign);
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          message: error.response?.data?.message || 'Ошибка создания сессии. Попробуйте позже',
          status: error.response?.status,
        });
      else return rejectWithValue({ message: 'Ошибка создания сессии. Попробуйте позже', status: 501 });
    }
  }
);

// создаём Thunk для получения актуальных параметров демо-блока и статуса кампании
export const getStatusCampaign = createAsyncThunk(
  'surveys/getStatusCampaign', // отображается в dev tools
  async (campaign: string, { rejectWithValue }) => {
    try {
      return await index.getStatusDemoParams(campaign);
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          message: error.response?.data?.message || 'Ошибка запроса актуальных параметров демо-блока. Попробуйте позже',
          status: error.response?.status,
        });
      else return rejectWithValue({ message: 'Ошибка запроса актуальных параметров демо-блока. Попробуйте позже', status: 501 });
    }
  }
);

interface StartTimerProps {
  autoStart?: boolean;
  surveyId: string;
  surveyPassingId: string;
  questionId: string;
  questionLabel?: string;
  isTimerAsync: boolean;
}

// создаём Thunk для запуска таймера
export const startTimer = createAsyncThunk(
  'surveys/startTimer', // отображается в dev tools
  async ({ autoStart = false, surveyId, surveyPassingId, questionId, questionLabel = 'timer', isTimerAsync }: StartTimerProps, { rejectWithValue, dispatch }) => {
    try {
      const state = store.getState() as RootState;
      const answers = state.surveys.answers;
      let currentAnswer = answers.find((a) => a.questionId === questionId) as IAnswer;
      const nowDateInMs = new Date().getTime();
      let summaryRunningTimeInitMs = 0

      if (!currentAnswer) {
        // Первый раз сохраняем ответ
        currentAnswer = {
          questionId,
          questionLabel,
          questionType: 'timer',
          value: '00:00:00',
          timer: {
            firstRunningStartTime: formatISO(nowDateInMs + new Date().getTimezoneOffset() * 60 * 1000).slice(0, -6) + 'Z',
            lastRunningPauseTime: '',
            lastRunningStartTime: formatISO(nowDateInMs + new Date().getTimezoneOffset() * 60 * 1000).slice(0, -6) + 'Z',
            runningStatus: 'START',
            summaryRunningTime: '00:00:00',
            timerStatus: 'NOT_COMPLETED'
          }
        }
        dispatch(addAnswer(currentAnswer));

      } else {
        summaryRunningTimeInitMs = getMsFromInputValue(currentAnswer.timer?.summaryRunningTime || '00:00:00')

        if (autoStart) {
          const offlineTimeFromApiMs = differenceInMilliseconds(
            new Date(),
            new Date(currentAnswer.timer?.lastRunningStartTime || 0),
          )

          const newValue = formatMs(offlineTimeFromApiMs + getMsFromInputValue(currentAnswer.timer?.summaryRunningTime || '00:00:00'))

          currentAnswer = Object.assign({}, currentAnswer, {
            value: newValue,
            timer: {
              ...currentAnswer.timer,
              summaryRunningTime: newValue,
              runningStatus: 'START',
              timerStatus: 'NOT_COMPLETED',
            }
          });
        } else {
          currentAnswer = Object.assign({}, currentAnswer, {
            timer: {
              ...currentAnswer.timer,
              lastRunningStartTime: formatISO(nowDateInMs + new Date().getTimezoneOffset() * 60 * 1000).slice(0, -6) + 'Z',
              runningStatus: 'START',
              timerStatus: 'NOT_COMPLETED',
            }
          });
        }

        // Сохранили в стейт что таймер запущен
        dispatch(updateAnswer(currentAnswer));
      }

      // Обновлять таймер нужно обязательно через расчет разницы, иначе таймер будет отставать когда вкладка не активна
      const timerId = window.setInterval(() => {
        if (!currentAnswer.timer?.lastRunningStartTime) return

        // считаем разницу от начала запуска таймера до текущего момента
        const offlineTimeFromApiMs = differenceInMilliseconds(
          new Date(),
          new Date(currentAnswer.timer.lastRunningStartTime),
        )

        const newValue = formatMs(offlineTimeFromApiMs + summaryRunningTimeInitMs)

        currentAnswer = Object.assign({}, currentAnswer, {
          value: newValue,
          timer: {
            ...currentAnswer.timer,
            summaryRunningTime: newValue,
            runningStatus: 'START',
            timerStatus: 'NOT_COMPLETED',
          }
        })

        dispatch(updateAnswer(currentAnswer));
      },1000);
      dispatch(addTimerId({ questionId, timerId }));

      // сразу отправим данные на бэк
      if (!autoStart) {
        const newAnswers = [...answers.filter(answer => answer.questionId !== currentAnswer.questionId), currentAnswer]
        dispatch(sendAnswersToBackend({ surveyId, surveyPassingId, allAnswers: newAnswers, operation: ERegisterAnswersOperation.SAVE }));
      }

      if (!isTimerAsync) {
        const timersShouldStop = answers
          .filter(answer => answer.questionId !== currentAnswer.questionId)
          .filter((answer) => answer.questionType === 'timer')
          .filter((answer) => answer.timer?.runningStatus === 'START')
        
        timersShouldStop.forEach((timer) => {
          dispatch(stopTimer({
            surveyId,
            surveyPassingId,
            questionId: timer.questionId,
          }))
        })
      }
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          message: error.response?.data?.message || 'Ошибка запроса запуска таймера. Попробуйте позже',
          status: error.response?.status,
        });
      else return rejectWithValue({ message: 'Ошибка запроса запуска таймера. Попробуйте позже', status: 501 });
    }
  }
);

interface StopTimerProps {
  surveyId: string;
  surveyPassingId: string;
  questionId: string;
}

// создаём Thunk для остановки таймера
export const stopTimer = createAsyncThunk(
  'surveys/stopTimer', // отображается в dev tools
  async ({ surveyId, surveyPassingId, questionId }: StopTimerProps, { rejectWithValue, dispatch }) => {
    try {
      const state = store.getState() as RootState;
      const answers = state.surveys.answers;
      const timersData = state.surveys.timers;
      const nowDateInMs = new Date().getTime();
      const currentAnswer = answers.find((a) => a.questionId === questionId) as IAnswer;
      const updatedAnswer = Object.assign({}, currentAnswer, {
        timer: {
          ...currentAnswer.timer,
          lastRunningPauseTime: formatISO(nowDateInMs + new Date().getTimezoneOffset() * 60 * 1000).slice(0, -6) + 'Z',
          runningStatus: 'STOP',
          timerStatus: 'COMPLETED',
        }
      });

      window.clearInterval(timersData.timerIds[questionId]);
      dispatch(delTimerId(questionId));
      dispatch(updateAnswer(updatedAnswer));
      const newAnswers = [...answers.filter(answer => answer.questionId !== updatedAnswer.questionId), updatedAnswer]
      // сразу отправим данные на бэк
      // в списке newAnswer обновим новый ответ
      dispatch(sendAnswersToBackend({ surveyId, surveyPassingId, allAnswers: newAnswers, operation: ERegisterAnswersOperation.SAVE }));
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          message: error.response?.data?.message || 'Ошибка запроса остановки таймера. Попробуйте позже',
          status: error.response?.status,
        });
      else return rejectWithValue({ message: 'Ошибка запроса остановка таймера. Попробуйте позже', status: 501 });
    }
  }
);

const initialState: ISurveysState = {
  timers: {
    timerIds: {},
    isTimerAsync: true,
    canTimerEdit: true,
  },
  isAnyQuestionDrugging: false,
  startLandingPage: '',
  demoParamsIsActual: false,
  demoParams: [],
  userId: '',
  sessionId: '',
  answers: [],
  questionsWithError: [],
  showErrorQuestions: false,
  ruleValues: [],
  rules: [],
  constructorIsVisible: false,
  isInputFocus: false,
  campaignName: '',
  manualFillingParametersLink: '',
  requestStatus: {},
  currentStageIndexGVK: 0
}

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    setIsAnyQuestionDrugging(state, action: PayloadAction<boolean>) {
      state.isAnyQuestionDrugging = action.payload;
    },
    addTimerId(state, action: PayloadAction<{ questionId: string, timerId: number }>) {
      state.timers.timerIds[action.payload.questionId] = action.payload.timerId;
    },
    delTimerId(state, action: PayloadAction<string>) {
      delete state.timers.timerIds[action.payload];

      return state
    },
    setCurrentStageIndexGVK(state, action: PayloadAction<number>) {
      state.currentStageIndexGVK = action.payload;
    },
    saveDemoParams(state, action: PayloadAction<Array<{ name: string; value: string }>>) {
      state.demoParams = action.payload;
    },
    saveUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    saveCampaignStatus(state, action: PayloadAction<IDemoInformation>) {
      state.demoParams = action.payload.actualParameters;
      state.campaignState = action.payload.campaignState;
      state.campaignStateDescription = action.payload.campaignStateDescription;
      state.manualFillingParametersLink = action.payload.manualFillingParametersLink || '';
      state.campaignName = 'campaign';
    },
    needActualiseDemoBlock(state) {
      state.demoParamsIsActual = false;
    },
    setAnswers(state, action: PayloadAction<Array<IAnswer>>) {
      state.answers = action.payload;
    },
    setIsInputFocus(state, action: PayloadAction<boolean>) {
      state.isInputFocus = action.payload;
    },
    addAnswer(state, action: PayloadAction<IAnswer>) {
      state.answers = [...state.answers.filter((a) => a.questionId !== action.payload.questionId), action.payload];
    },
    updateAnswer(state, action: PayloadAction<IAnswer>) {
      state.answers = state.answers.map((a) => {
        if (a.questionId === action.payload.questionId) {
          return action.payload;
        }

        return a;
      })

      return state
    },
    delAnswer(state, action: PayloadAction<string>) {
      state.answers = [...state.answers.filter((a) => a.questionId !== action.payload)];
    },
    addQuestionWithError(state, action: PayloadAction<IQuestionWithError>) {
      if (!state.questionsWithError.find((q) => q.id === action.payload.id))
        state.questionsWithError = [...state.questionsWithError, action.payload].sort((a, b) => a.position - b.position);
    },
    delQuestionWithError(state, action: PayloadAction<string>) {
      state.questionsWithError = state.questionsWithError.filter((q) => q.id !== action.payload);
    },
    setShowErrors(state, action: PayloadAction<boolean>) {
      state.showErrorQuestions = action.payload;
    },
    setRuleValues(state, action: PayloadAction<Array<IRuleValue>>) {
      state.ruleValues = [...action.payload];
    },
    setVisibleRuleConstructor(state, action: PayloadAction<boolean>) {
      state.constructorIsVisible = action.payload;
    },
    setRulesList(state, action: PayloadAction<Array<IRule>>) {
      state.rules = [...action.payload];
    },
    setCampaignName(state, action: PayloadAction<string>) {
      state.campaignName = action.payload;
    },
    resetSurveySlice() {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      // *** запрос создания сессии ***
      // Вызывается прямо перед выполнением запроса
      .addCase(sessionOpen.pending, (state) => {
        // state.loading = true;
        if (!state.requestStatus['sessionOpen']) state.requestStatus['sessionOpen'] = {};
        state.requestStatus['sessionOpen'].loaded = false;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(sessionOpen.fulfilled, (state, action) => {
        // Добавляем информацию
        state.userId = action.payload.userId || '';
        state.requestStatus['sessionOpen'] = {
          loaded: true,
          status: 200,
          message: undefined,
        };
      })
      // Вызывается в случае ошибки
      .addCase(sessionOpen.rejected, (state, action) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        state.requestStatus['sessionOpen'] = {
          loaded: true,
          status: (action?.payload as any)?.status || 501,
          message: action.error.message,
        };
      })
      // *** запрос статуса кампании ***
      // Вызывается прямо перед выполнением запроса
      .addCase(getStatusCampaign.pending, (state) => {
        // state.loading = true;
        if (!state.requestStatus['getStatusCampaign']) state.requestStatus['getStatusCampaign'] = {};
        state.requestStatus['getStatusCampaign'].loaded = false;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getStatusCampaign.fulfilled, (state, action) => {
        // Добавляем информацию
        state.demoParams = action.payload.actualParameters;
        state.campaignState = action.payload.campaignState;
        state.manualFillingParametersLink = action.payload.manualFillingParametersLink || '';
        state.campaignName = action.meta.arg;
        state.requestStatus['getStatusCampaign'] = {
          loaded: true,
          status: 200,
          message: undefined,
        };
      })
      // Вызывается в случае ошибки
      .addCase(getStatusCampaign.rejected, (state, action) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        state.requestStatus['getStatusCampaign'] = {
          loaded: true,
          status: (action?.payload as any)?.status || 501,
          message: action.error.message,
        };
      });
  },
});

export const {
  addTimerId,
  delTimerId,
  setIsAnyQuestionDrugging,
  saveDemoParams,
  saveUserId,
  saveCampaignStatus,
  needActualiseDemoBlock,
  setVisibleRuleConstructor,
  setRulesList,
  setAnswers,
  addAnswer,
  updateAnswer,
  delAnswer,
  addQuestionWithError,
  delQuestionWithError,
  setShowErrors,
  setRuleValues,
  setCampaignName,
  setIsInputFocus,
  resetSurveySlice,
} = surveysSlice.actions;
export default surveysSlice.reducer;
