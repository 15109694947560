export const ISO_DATE = 'YYYY-MM-DD[T]HH:mm:ss.sssZ';

export interface ITime {
	hours: number
	minutes: number
	seconds: number
}

export const formatMs = (ms: number): string => {
	const hours = Math.floor(ms / (1000 * 60 * 60));
	const minutes = Math.floor(ms % (1000 * 60 * 60) / (1000 * 60));
	const seconds = Math.floor(ms % (1000 * 60) / 1000);

	return [
		String(hours).padStart(2, '0'),
		String(minutes).padStart(2, '0'),
		String(seconds).padStart(2, '0'),
	].join(':');
}

export const getMsFromInputValue = (inputValue?: string): number => {
	const time = convertStringToTime(inputValue || '00:00:00')

	return time.hours * 60 * 60 * 1000 + time.minutes * 60 * 1000 + time.seconds * 1000
}

export const convertStringToTime = (stringValue: string): ITime => {
	const valueArray = stringValue.split(':');
	return {
		seconds: valueArray.length > 0
			? isNaN(parseInt(valueArray[valueArray.length-1]))
				? 0
				: parseInt(valueArray[valueArray.length-1])
			: 0,
		minutes: valueArray.length > 1
			? isNaN(parseInt(valueArray[valueArray.length-2]))
				? 0
				: parseInt(valueArray[valueArray.length-2])
			: 0,
		hours: valueArray.length > 2
			? isNaN(parseInt(valueArray[valueArray.length-3]))
				? 0
				: parseInt(valueArray[valueArray.length-3])
			: 0,
	}
}

export const convertTimeToString = (timeValue: ITime) => {
	return `${timeValue.hours.toString().padStart(2,'0')}:${timeValue.minutes.toString().padStart(2,'0')}:${timeValue.seconds.toString().padStart(2,'0')}`
}

export const getStartValue = (time: any): ITime => {
	if (typeof time === 'string') {
		return convertStringToTime(time)
	}

	return {seconds: 0, minutes: 0, hours: 0}
}

export const getSummaryRunningTimeAsSeconds = (sumRunTime: string): number => {
	const sumRunTimeAsTime: ITime = convertStringToTime(sumRunTime);

	return sumRunTimeAsTime.seconds + sumRunTimeAsTime.minutes*60 + sumRunTimeAsTime.hours*60*60;
}

export const getDifferenceBetweenLastStartAndNow = (lastRunStartTime: Date): number => {
	const now = new Date();
	const diff = +now - +lastRunStartTime;
	return diff / 1000;
}

export const getDateFromTimeString = (dateString?: string): Date => {
	if (!dateString) return new Date();

	return new Date(dateString);
}

export const FORMAT_CHARS = {
	'0': '[0-2]',
	'1': '[0-2]',
	'2': '[0-9]',
	'3': '[0-5]',
	'4': '[0-9]'
}