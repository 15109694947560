import React, {lazy, useCallback, useEffect, useState} from 'react';
import styles from './App.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Route, useNavigate, Routes, useLocation } from 'react-router-dom';


// импортим основные компоненты - сервисы
import {allowedExtURI, Auth} from './components/Auth';
import Header from './components/Header';
import Footer from './components/Footer';
import { SurveysRoutes } from './components/Surveys/SurveysRoutes';
import { useAlert } from 'react-alert';
import { YandexMetrika } from './components/YandexMetrika';
import { index } from './api/authorization';
import { IUserInfo } from './redux/constants';
import jwt_decode from 'jwt-decode';
import { logIn } from './redux/slices/authSlice';
import { setAvailableModules } from './redux/slices/availableModulesSlice';
import { Preloader } from './components/Preloader';
import { isIE } from 'react-device-detect';
import { BannerIEDetected } from './components/BannerIEDetected';
import * as queryString from 'query-string';
import Status404 from './components/StatusError/Status404';
import { systemPreferencesAPI } from './api/systemPreferences/systemPreferencesAPI';
import { SurveyConstructorRoutes } from './components/SurveyConstructor/SurveyConstructorRoutes';
import StatusSomeError from './components/StatusError/StatusSomeError';
import { useDispatch } from 'react-redux';
import { CampaignRoutes } from './components/SurveyCampaigns_New/CampaignRoutes';
import { SurveyCampaignsRoutes } from './components/SurveyCampaigns/Routes';
import { LandingPagesRoutes } from './components/LandingPages/Routes';
import { ServiceUnavailable } from './components/ServiceUnavailable';
import { AiRoutes } from './components/Ai/AiRoutes';
import cn from 'classnames';
import {titles} from "./utils";
import {IUserTokenDTO} from './api/authorization/models/IUserTokenDTO';
import {MeetingsRoutes} from './components/Meetings/Routes';
import {ProjectRoutes} from './components/Projects/Routes';
import {ProjectsSpecRoutes} from './components/ProjectsSpec/Routes';
import {DashboardRoutes} from "./components/SurveysDashboard/DashboardRoutes";
import {LandingConstructorRoutes} from './components/LandingConstructor/Routes';
import {LegacyRoutes} from './components/AuthForLegacy/Routes';
import {StatusError} from './components/StatusError';
import {AdminManagementRoutes} from './components/AdminManagement/Routes';
import {ErrorBoundary} from 'react-error-boundary';
import {ReactComponent as NewLogo} from 'img/Sberboost/newLogo.svg';
import { LeanLabRoutes } from "./components/LeanLabPage/Routes";

const Home = lazy(() => import('./components/Home').then(({ Home }) => ({default: Home})));

function App() {
  const alert = useAlert(); // для показа алертов

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [status, setStatus] = useState(0);

  useEffect(
    () => {
      if (location.pathname.includes('chamomile'))
        document.title = 'Ромашка'
      else
        document.title = titles[location.pathname] ?? 'Сберидея';

      // механизм хранения предыдущего и текущего пути в локальном хранилище
      const currentRouteSaved = localStorage.getItem('currentRoute') || '';
      const currentRoute = (location.pathname || '') + (location.search || '');
      // перекодируем параметры строки в объект
      const searchParamsObj = queryString.parse(location.search || '');
      // список исключений-роутов, находясь на которых менять историю переходов не надо
      if ((location.pathname === '/auth') || // страница авторизации
        (location.pathname === '/' && searchParamsObj.state && searchParamsObj.code)) // вернулись из СУДИР для обмена кода на токен
        return;
      if (currentRouteSaved !== currentRoute) {
        // сохранённый текущий путь не равен тому, где мы находимся
        // произошёл переход
        // сохраняем предыдущий путь
        localStorage.setItem('prevRoute', currentRouteSaved);
        // сохраняем текущий путь
        localStorage.setItem('currentRoute', currentRoute);
      }
    }, [location]
  )

  // готовым считается тогда, когда необходимый токен авторизации получен
  const [ready, setReady] = useState(false); // флаг готовности работы приложения

  // функция записи инфо о юзере в редакс
  const saveUserInRedux = useCallback(
    (accessToken: string) => {
      const jwtDecoded: any = jwt_decode(accessToken);
      const user: IUserInfo = {
        name: jwtDecoded?.user_name || 'User',
        role: jwtDecoded?.user_type || 'user',
        access_token: accessToken,
      };
      dispatch(logIn(user));
    },
    [dispatch]
  );

  useEffect(
    // при загрузке проверяем, не пришли ли мы из СУДИР
    () => {
      const getToken = async () => {
        // читаем переданные параметры в строке
        const path: string = location.pathname;
        const searchParams: string = location.search;
        // признак того, что пришли из легаси-платформы
        const fromLegacy = localStorage.getItem('fromLegacy');
        // удаляем флаг из локального хранилища
        if (fromLegacy) localStorage.removeItem('fromLegacy');
        // перекодируем их в объект
        const searchParamsObj = queryString.parse(searchParams);
        // признак того, что пришли из СУДИР
        const fromSUDIR = path === '/' && searchParamsObj.state && searchParamsObj.code;
        // ищем поля state и code
        if (fromSUDIR) {
          // если они есть, то значит мы попали сюда после редиректа от СУДИР
          let tokenReceived = false; // получен ли токен
          try {
            await index.logout();
            const token: IUserTokenDTO = await index.getSudirToken(searchParams);
            saveUserInRedux(token.accessToken || '');
            tokenReceived = true;
          } catch (error) {
            alert.show(error.response?.data?.message || 'Ошибка авторизации в СУДИР', { type: 'error' });
          }

          // если токен не получен, то получаем гостевой токен
          if (!tokenReceived) {
            try {
              const token: IUserTokenDTO = await index.getGuestToken();
              saveUserInRedux(token.accessToken || '');
            } catch (error) {
              setStatus(error?.response?.status || 501);
            }
          }

          // если стоит флаг fromLegacy, то нужно вернуться на легаси-площадку
          if (fromLegacy) {
            localStorage.removeItem('fromLegacy');
            // localStorage.removeItem('urlRedirect');
            window.location.href = 'https://sbidea.ru/'; // переход на легаси
          } else {
            // const url = localStorage.getItem('urlRedirect') || '/';
            // localStorage.removeItem('urlRedirect');
            const url = localStorage.getItem('currentRoute') || '/';
            /**
             * частный случай переходов с полной перезагрузкой
             * - на Гемба
             * - на Сбербуст
             */
            if ((allowedExtURI.map(uri => `/${uri}/`).find(allowedURI => url.startsWith(allowedURI)) || (url === '/sberboost')) && (localStorage.getItem('reload'))) {
              localStorage.removeItem('reload');
              window.location.replace(url);
            } else {
              navigate(url); // редирект
            }
          }
        } else {
          // параметров нет
          let tokenReceived = false; // получен ли токен
          try {
            const token: IUserTokenDTO = await index.refreshToken(); // рефреш токена
            saveUserInRedux(token.accessToken || '');
            tokenReceived = true;
          } catch (error) {}

          if (!tokenReceived) {
            try {
              const token: IUserTokenDTO = await index.getGuestToken();
              saveUserInRedux(token.accessToken || '');
            } catch (error) {
              setStatus(error?.response?.status || 501);
            }
          }
        }

        // если пришли с легаси, то дальше код не выполняем
        // просто крутим прелоадер и ждём перенаправления
        if (fromLegacy && fromSUDIR) return;

        try {
          // запрос доступных модулей системы
          const res = await systemPreferencesAPI.getAvailableModulesOnPlatform();
          // сохраняем инфу в редаксе
          if (Array.isArray(res?.value?.modules)) {
            dispatch(setAvailableModules(res.value.modules));
          }
        } catch (error) {
          alert.show(error.response?.data?.message || 'Ошибка получения доступных модулей системы', { type: 'error' });
          setStatus(error?.response?.status || 501);
        }

        // редирект на место, откуда пришли
        // const url = localStorage.getItem('urlRedirect');
        // if (url) navigate(url);
        // localStorage.removeItem('urlRedirect');

        // const redirectOnStartApp = localStorage.getItem('redirectOnStartApp') || '';
        // if (redirectOnStartApp === '1') {
        //   const currentRoute = localStorage.getItem('currentRoute') || '/';
        //   navigate(currentRoute);
        // }
        // удалим флаг принудительного редиректа
        // localStorage.removeItem('redirectOnStartApp');
        setReady(true);
      };

      getToken();
    },
    [alert, dispatch, saveUserInRedux]
  );

  const isGVK = window.location.href.match(/pro_mnenie/gi) || window.location.href.match(/landings[\/]PRO_Mnenie/gmi)
  const isLandingConstructor = window.location.href.match(/landing-constructor/gi);
  const isSurveyConstructor = window.location.href.match(/survey-constructor/gi);
  const isSberBoostCreatePage = window.location.href.match(/sberboost[\/]create/gi);
  const isSberboost = window.location.href.match(/sberboost/gi);

  // если токен не готов, то показываем прелоадер
  if (!ready) return <Preloader text='Мы направляем Вас на нужную страницу. Ожидаем вместе с Вами'/>;

  if (status>=400)
    return <StatusError status={status}/>

  return (

    <ErrorBoundary
      FallbackComponent={StatusSomeError}
    >
      <YandexMetrika>
        {
        !isGVK && <Header updateLogo={isSberboost ? <NewLogo/> : undefined}
                          urlInsteadLogo={isSberboost ? "/sberboost" : undefined}
        />
        }
        <main className={cn(styles.app, isGVK && styles.app_with_GVK)} data-testid="app-component">
          {isIE && <BannerIEDetected />}
          <div className={ styles.app_content }>
            <React.Suspense fallback={<Preloader />}>
              <Routes>
                <Route path="/">
                  <Route index element={<Home/>} />
                  <Route path="auth" element={<Auth />} />
                  <Route path="*" element={<Status404 />} />
                  {AdminManagementRoutes}
                  {LegacyRoutes}
                  {SurveysRoutes}
                  {SurveyCampaignsRoutes}
                  {CampaignRoutes}
                  {SurveyConstructorRoutes}
                  {LandingPagesRoutes}
                  {AiRoutes}
                  {MeetingsRoutes}
                  {ProjectRoutes}
                  {ProjectsSpecRoutes}
                  {DashboardRoutes}
                  {LandingConstructorRoutes}
                  {LeanLabRoutes}
                  <Route path="not-available" element={<ServiceUnavailable serviceName="" />} />
                </Route>
              </Routes>
            </React.Suspense>
          </div>
        </main>
        {!isLandingConstructor && !isSberBoostCreatePage && !isSurveyConstructor && <Footer />}
      </YandexMetrika>
    </ErrorBoundary>
  );
}

export default App;