import {lazy} from 'react';
import {Outlet,Route} from 'react-router-dom';
import {CheckAuth} from "HOC/CheckAuth";

const MainPage = lazy(() => import('../pages').then(({MainPage}) => ({ default: MainPage })));
const ResearchViewPage = lazy(() => import('../pages').then(({ResearchViewPage}) => ({ default: ResearchViewPage })));
const ResearchEditPage = lazy(() => import('../pages').then(({ResearchEditPage}) => ({ default: ResearchEditPage })));

const BASE_URL = '/lean-lab'

export const LEANLAB_ROUTES = {
	main: {
		getPath: () => `${BASE_URL}`,
	},
	orderView: {
		getPath: (id: string) => `${BASE_URL}/${id}`,
	},
	orderEdit: {
		getPath: (draftId?: string) => `${BASE_URL}/create-research/${draftId || ''}`,
	}
}

const LeanLabRoutes = (
	<Route path='/lean-lab' element={<Outlet />}>
		<Route index element={<CheckAuth><MainPage/></CheckAuth>}/>
		<Route path=":id">
			<Route index element={<CheckAuth><ResearchViewPage /></CheckAuth>}/>
		</Route>
		<Route path='create-research'>
			<Route index element={<CheckAuth><ResearchEditPage /></CheckAuth>}/>
			<Route path=":draftId" element={<CheckAuth><ResearchEditPage /></CheckAuth>}/>
		</Route>
	</Route>
);

export { LeanLabRoutes }